<template>
  <div class="pay-code">
    <div v-if="currentPayType == 1" class="title">
      <template v-if="payWayId == 'wx'"> 微信 </template>
      <template v-if="payWayId == 'zfb'"> 支付宝 </template>
      <template v-if="payType == 'xianjin'"> 现金 </template>
    </div>
    <div class="code">
      <!-- 现金支付 -->
      <template v-if="!(payWayId== 'wx'||payWayId== 'zfb')">
        <cash :curentOrderInfo="curentOrderInfo" :payInfo="curentOrderInfo"></cash>
      </template>
      <!-- 非现金支付 -->
      <template v-else>
        <!-- 1 二维码支付 -->
        <template v-if="currentPayType == 1">
          <!-- 二维码支付分为 微信 支付宝 和现金 -->
          <template v-if="qrCodeSrc && qrCodeSrc.length > 0">
            <div class="pay-price" v-if="xianjinStr">¥{{ Number(zhifu).toFixed(2) }}</div>
            <div class="pay-price" v-else>¥{{ curentOrderInfo.unpaidPrice.toFixed(2)||curentOrderInfo.unpaidPrice }}</div>
            <qrcode-vue :value="qrCodeSrc" :size="200" />
          </template>
          <template v-else>
            <el-button @click="handleSetCodeClick" type="danger">重新获取支付二维码</el-button>
            <!-- <div>生成支付二维码失败,正在重新请求...</div> -->
          </template>
        </template>

        <!-- 扫码枪支付 -->
        <template v-else>
          <div class="pay-price" v-if="xianjinStr">¥{{ Number(zhifu).toFixed(2) }}</div>
          <div class="pay-price" v-else>¥{{ curentOrderInfo.unpaidPrice.toFixed(2)||curentOrderInfo.unpaidPrice}}</div>
          <img src="@/assets/img/main/order/saomaqiang.png" alt="" />
        </template>
        <div class="opt-pay">
          <!-- 1 二维码 0扫码枪 -->
          <template v-if="currentPayType == 1">
            <div @click="handleOptPayClick(0)">切换扫码枪支付</div>
          </template>
          <!-- payType如果是微信、支付宝 显示 -->
          <template v-else>
            <div @click="handleOptPayClick(1)">切换二维码支付</div>

            <div class="codeNum">
              <el-input @blur="codeNumblur" @focus="codeNumfocus" clearable ref="codeNumRef"
                @change="handleTestInputChange" v-model="codeNum" placeholder="扫码枪支付" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, watch,computed } from 'vue'
import emitter from '@/utils/eventbus'
import { getOrderPayState, setKaidanShouDongdk, setUserCard, setUserRecharge, setOrderFinsh } from '@/service/main/order'
import { ElMessage } from 'element-plus'
import QrcodeVue from 'qrcode.vue'
import { useRouter } from 'vue-router'
import { getPayQrcode } from '@/service/main/order'
import { emit } from 'process'
import { setOrderBack } from '@/service/main/order'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import { throttle, debounce } from 'lodash'
import { updateZuheInfo, saomaPay, setOrderRecordPaytype, setOrderCommission, zekouCardLog, setXiaosouYeji, setXiaohaoYeji, addTicheng } from '@/service/main/order'
import cash from '../cash/cash.vue'
import cache from '@/utils/cache'
import { reserveAddTicheng } from '@/service/main/reserve'
import { messageNotify1 } from '@/service/main/base'
import { getOrderDetail } from '@/service/main/order'

export default defineComponent({
  props: {
    oid: {
      type: Number,
      default: -1
    },
    orderKind: {
      type: Number,
      default: 0
    },
    payPrice: {
      type: Number,
      default: 0
    },
    isZuheFlag: {
      type: Boolean,
      default: false
    },
    isBack: {
      type: Boolean,
      default: false
    },

    isKaika: {
      type: Boolean,
      default: false
    },
    reserveNo: {
      type: Number,
      default: -1
    },
    isSetZuheXianjinPayCode: {
      type: Boolean,
      default: false
    },
    // 当前支付方式 0 微信 1支付宝 2现金
    currentOnlinePayType: {
      type: Number,
      default: 0
    },
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },

  },
  components: {
    QrcodeVue,
    cash
  },
  emits: ['paySuccess'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const testInput = ref('')
    const xianjinStr = ref(cache.getCache('currentXianjinArr'))
    const zhifu = ref(cache.getCache('curentOnlinePrice'))
    const order = computed(() => props.curentOrderInfo)
    const payWayId = ref('wx')
    const getPayWay = () => {
        if (order.value.payWay) {
          payWayId.value=order.value.payWay
        }else{
          payWayId.value='wx'
        }
    }
    getPayWay()
    watch(order, (n) => {
      if (n.payWay) {
        payWayId.value=n.payWay
      }
    }, {
      deep: true
    })

    const handleTestInputChange = async (value) => {
      // console.log(value);
      if (value.length > 0) {
        ElMessage('扫码枪支付')
        const currentOptPayType = props.currentOnlinePayType
        if (codeTimer) {
          clearTimeout(codeTimer)
        }
        // 如果是现金支付 或者扫码枪支付 不请求支付二维码
        if (payType.value == 'xianjin') return
        let payTypeData=currentOptPayType==0?1:currentOptPayType==1?2:''
        let obj;
        if (xianjinStr.value) {
          let currentXianjinArr=cache.getCache('currentXianjinArr')
          let curentXianjinPriceArr=cache.getCache('curentXianjinPriceArr')
          obj={id:props.curentOrderInfo.id,payType:payTypeData,price:zhifu.value,secondPayWay:{pawWay:currentXianjinArr,price:curentXianjinPriceArr}}
        }else{
          obj={id:props.curentOrderInfo.id,payType:payTypeData}
        }
        const res = await getPayQrcode({
          ...obj,
          payCode: value
        })

        if (res.code != 0) {
          ElMessage({
            message: '操作失败,请重新扫码!',
            type: 'warning',
          })
        }
      }
      // 清楚扫码枪获取的东西
      codeNum.value = ''
    }


    const currentPayType = ref(0)
    const qrCodeSrc = ref('')
    const isFirstClick = ref(true)
    const payType = ref('wechat')
    const newOid = ref(props.oid)
    const handleOptPayClick = (type) => {
      // type 1二维码 0扫码枪
      if (currentPayType.value == 0) {
        currentPayType.value = 1
      } else {
        currentPayType.value = 0
      }
      // 如果是扫码枪直接中断
      if (type == 0) return


      // currentOnlinePayType索引为0 微信支付 1支付宝支付
      const currentOptPayType = props.currentOnlinePayType
      let payType = 0
      if (currentOptPayType == 0) {
        payType = 'wechat'
      } else if (currentOptPayType == 1) {
        payType = 'alipay'
      }

      // 请求二维码
      setQrCode()
      // 轮询支付状态
      getPayState(props.curentOrderInfo.id)
      emit('setPayCodeClick')
      // 如果是第一次点击 生成支付二维码
      if (isFirstClick.value == true) {
        isFirstClick.value == false
        emitter.emit('changeQrcodePageOrderPayType')
      }
    }

    const handleCashPayClick = () => {
      // 设置为二维码支付
      currentPayType.value = 1
    }

    let retryNum = ref(0)
    let codeTimer = null

    const handleSetCodeClick = () => {
      const currentOptPayType = props.currentOnlinePayType
      let payType = 0
      if (currentOptPayType == 0) {
        payType = 'wechat'
      } else if (currentOptPayType == 1) {
        payType = 'alipay'
      }
      setQrCode()
    }

    const setQrCode = async (payQrcodeParams) => {
      const currentOptPayType = props.currentOnlinePayType
      if (codeTimer) {
        clearTimeout(codeTimer)
      }
      // 如果是现金支付 或者扫码枪支付 不请求支付二维码
      if (payType.value == 'xianjin' || currentPayType.value == 0) return
      let payTypeData=currentOptPayType==0?1:currentOptPayType==1?2:''
      let obj;
      if (xianjinStr.value) {
        let currentXianjinArr=cache.getCache('currentXianjinArr')
        let curentXianjinPriceArr=cache.getCache('curentXianjinPriceArr')
        obj={id:props.curentOrderInfo.id,payType:payTypeData,price:zhifu.value,secondPayWay:{pawWay:currentXianjinArr,price:curentXianjinPriceArr}}
      }else{
        obj={id:props.curentOrderInfo.id,payType:payTypeData}
      }
      const payQRcode = await getPayQrcode(obj)
      if (payQRcode.code != 0 && retryNum.value <= 60) {
        codeTimer = setTimeout(() => {
          if (qrCodeSrc.value.length == 0) {
            ElMessage({
              message: '请求失败,请点击按钮重试!',
              type: 'warning',
            })
          }
        }, 3000);
      } else {
        clearTimeout(codeTimer)
        qrCodeSrc.value = payQRcode.data.url
      }

      if (retryNum.value > 60) {
        ElMessage({
          message: '支付二维码请求异常，请关闭支付弹框重试!',
          type: 'warning',
        })
      }
    }

    watch(() => props.isZuheFlag, (n) => {
      clearTimeout(codeTimer)
    }, {
      deep: true
    })

    emitter.on('changeOrderPayTypePageSetQrcode', async _payType => {
      payType.value = _payType
      if (_payType=="xianjin") {
        initPay()
      }else{
        flag = true
        getPayState(props.curentOrderInfo.id)
        setQrCode()
      }
    })

    const isPaySuccess = ref(false)
    // 生成支付二维码
    emitter.on('changeOrderPayDialogSearchPayState', () => {
      const func = throttle(() => {
        isPaySuccess.value = false
        getPayState(props.curentOrderInfo.id)
      }, 1000)
      func()
    })

    var timer = null
    let flag = true

    const getPayState = async (subOrderNo) => {
      // 判断是否关闭轮询
      if (flag == false) return
      // 如果是扫码枪支付 让输入框获取焦点
      if (currentPayType.value == 0 && iscodeNumFocus.value == false && codeNumRef.value) {
        codeNumRef.value.focus()
      }

      if (timer != null) {
        clearTimeout(timer)
      }

      // 轮询支付状态
      const res = await getOrderPayState(
        subOrderNo
      )

      if (res.data.payStatus==1) {
        // 请求支付二维码失败 重新发起请求
        timer = setTimeout(() => {
          getPayState(subOrderNo)
        }, 2000);
      } else if (res.code == 0&&res.data.payStatus==2) {
        // props.oid = -1
        initPay()
        isPaySuccess.value = true
        // if (props.orderKind == 4) {
        //   await setOrderFinsh({
        //     orderNo: subOrderNo,
        //     companyId: COMPANY_ID,
        //     appNo: props.reserveNo
        //   })
        // } else if (props.orderKind == 6 || props.orderKind == 5) {
        //   await setKaidanShouDongdk(subOrderNo)
        // }

        //  // 获取订单详情
        // const detailRes = await getOrderDetail(COMPANY_ID, props.curentOrderInfo.oid)
        // let { orderDetail, empinfo, payinfo, serveItems } = detailRes.data
        // let { subOrderNo, shopName, userId } = orderDetail
        // let appTime = serveItems[0]['arriveDate'] + ' ' + serveItems[0]['startTime']
        // let messageObj = {
        //   orderNo: subOrderNo,
        //   Price: payinfo.payPrice / 100,
        //   shopName,
        //   appTime,
        //   userId,
        //   empName: empinfo[0]['userRealname']
        // }

        // // 后台开单使用现金/在线支付通知
        // await messageNotify1(messageObj)

        emit('paySuccess')
        ElMessage({
          message: '支付成功！',
          type: 'success',
        })
        // cache.deleteCache('currentPayEmpid')

        // let curentOrderInfo = props.curentOrderInfo
        // let onlinePayType = props.currentOnlinePayType
        // let oldPayTypeArr = cache.getCache('currentpayTypeArr')
        // let xianjinStr = cache.getCache('currentXianjinArr')
        // let curretnOnlinePrice = cache.getCache('curentOnlinePrice')
        // let servediscountStr = cache.getCache('servediscountStr')
        // let servediscountprice = cache.getCache('servediscountprice')

        // let payPriceArr = []
        // let payTypeArr = []
        // // 判断是否是组合支付(权益+线上/储值卡+线上/余额+线上)
        // if (oldPayTypeArr != undefined) {
        //   let curentPayPriceArr = cache.getCache('curentPayPriceArr')
        //   payPriceArr = curentPayPriceArr
        //   payTypeArr = oldPayTypeArr
        // }

        // // 判断是否是现金+线上组合支付(微信+现金/余额+现金)
        // if (xianjinStr != undefined) {
        //   let xianjinPriceStr = cache.getCache('curentXianjinPriceArr')
        //   payTypeArr.push(xianjinStr)
        //   payPriceArr.push(xianjinPriceStr)
        // }

        // if (servediscountStr != undefined) {
        //   payTypeArr.push(servediscountStr)
        //   payPriceArr.push(servediscountprice)
        // }

        // payTypeArr.push("danci_0")
        // // 判断是否现金+线上组合支付
        // if (xianjinStr == undefined) {
        //   payPriceArr.push(curentOrderInfo.unpaidPrice)
        // } else {
        //   payPriceArr.push(curretnOnlinePrice)
        // }
        // try {
        //   await setOrderRecordPaytype({
        //     orderNo: curentOrderInfo.oid,
        //     payKind: curentOrderInfo.payKind,
        //     payPrice: payPriceArr.join(),
        //     payWay: payTypeArr.join()
        //   })
        // } catch (error) {
        //   // console.log(error);
        // }
        emptyPayArr()
        // jiluTicheng()
        cache.deleteCache('currentPayEmpid')

        // console.log(curentOrderInfo);
        // if (curentOrderInfo.isOpenKaika) {
        //   router.push('/main/reserve/check')
        // } else {
        //   router.push('/main/reserve/check')
        // }

      }
    }

    // 记录提成
    const jiluTicheng = async () => {
      let payinfo = props.curentOrderInfo
      let orderType = payinfo.payKind
      // console.log('--------------1211111');
      const empId = cache.getCache('currentPayEmpid')
      let { oid, buyshopId } = payinfo
      if (empId == '' || empId == 0) return
      if (orderType != 1) {
        let res1 = await setXiaosouYeji({
          orderNo: oid,
          empId,
          shopId: buyshopId
        })

        try {
          setTimeout(() => {
            if (res1.code != 0) {
              ElMessage({
                message: res1.msg,
                type: 'warning',
              })
            }
          }, 1000);
        } catch (error) {
          // console.log(error);
        }

        let res2 = await addTicheng({
          orderNo: oid,
          empId,
          shopId: buyshopId,
          bindKind: 2
        })

        try {
          setTimeout(() => {
            if (res2.code != 0) {
              ElMessage({
                message: res2.msg,
                type: 'warning',
              })
            }
          }, 2000);
        } catch (error) {
          // console.log(error);
        }


      } else {
        const res = await setXiaohaoYeji({
          orderNo: oid,
          empId,
          shopId: buyshopId
        })
        if (res.code != 0) {
          ElMessage({
            message: res.msg,
            type: 'warning',
          })
        }

        let res2 = await reserveAddTicheng({
          orderNo: oid,
          empId,
          shopId: buyshopId,
          bindKind: 1
        })
        if (res2.code != 0) {
          ElMessage({
            message: res2.msg,
            type: 'warning',
          })
        }

      }

      cache.deleteCache('currentPayEmpid')
    }


    const emptyPayArr = () => {
      cache.deleteCache('curentPayPriceArr')
      cache.deleteCache('currentpayTypeArr')
	  // cache.deleteCache('lastPayType')
      cache.deleteCache('currentXianjinArr')
      cache.deleteCache('curentXianjinPriceArr')
      cache.deleteCache('curentOnlinePrice')
      cache.deleteCache('servediscountprice')
    }

    // 监听现金支付成功 从cash.vue发出
    emitter.on('changeOrderBillingCashPaySuccess', async () => {
      // if (props.orderKind == 4) {
      //   await setOrderFinsh({
      //     orderNo: props.oid,
      //     companyId: COMPANY_ID,
      //     appNo: props.reserveNo
      //   })
      // } else if (props.orderKind == 6 || props.orderKind == 5) {
      //   await setKaidanShouDongdk(props.oid)
      // }



      initPay()
      emit('paySuccess')
      ElMessage({
        message: '支付成功！',
        type: 'success',
      })
      cache.deleteCache('currentPayEmpid')

    //   let curentOrderInfo = props.curentOrderInfo
    //   let onlinePayType = props.currentOnlinePayType
    //   let oldPayTypeArr = cache.getCache('currentpayTypeArr')
	  // console.log(cache.getCache('lastPayType'));
	  // let lastPayType = cache.getCache('lastPayType') || 'Cash_0'
    //   let payPriceArr = [curentOrderInfo.unpaidPrice]
    //   let payTypeArr = [lastPayType]

    //   // 判断是否是组合支付
    //   if (oldPayTypeArr != undefined) {
    //     let curentPayPriceArr = cache.getCache('curentPayPriceArr')
    //     payPriceArr = [...payPriceArr, ...curentPayPriceArr]
    //     payTypeArr = [...payTypeArr, ...oldPayTypeArr]
    //   }
 
    //   try {
    //     await setOrderRecordPaytype({
    //       orderNo: curentOrderInfo.oid,
    //       payKind: curentOrderInfo.payKind,
    //       payPrice: payPriceArr.join(),
    //       payWay: payTypeArr.join(),
    //     })
    //   } catch (error) {
    //     // console.log(error);
    //   }
      emptyPayArr()
      // jiluTicheng()
      // if (curentOrderInfo.isOpenKaika) {
      //   router.push('/main/reserve/check')
      // } else {
      //   router.push('/main/reserve/check')
      // }

    })

    // 初始化支付
    const initPay = () => {
      flag = false
      clearTimeout(window.timer)
      timer = null
      qrCodeSrc.value = ''
      currentPayType.value = 0
      isFirstClick.value = true
    }



    // 关闭支付
    emitter.on('changeOrderPayDialogClose', async () => {
      initPay()
      if (props.isBack == true && isPaySuccess.value == false || props.isBack == true && isFirstClick.value == true) {
        await setOrderBack({
          orderNo: props.oid,
          payPrice: props.payPrice,
          combineFlag: 0,
          rollBack: 1
        })
      }

      ElMessage({
        message: '关闭支付!',
        type: 'warning',
      })

      if (props.isSetZuheXianjinPayCode == true) {
        await updateZuheInfo({
          subOrderNo: props.oid,
          payPrice: props.payPrice,
          cashPrice: 0
        })
      }
      const servediscountprice = cache.getCache('servediscountprice')
      const servediscountStr = cache.getCache('servediscountStr')

      // 如果之前选择过折扣 恢复原价
      if (servediscountprice != undefined) {
        await zekouCardLog({
          orderNo: props.oid,
          promprice: -servediscountprice,
          cardNo: servediscountStr.split('-')[1]
        })
      }
      if (cache.getCache('servediscountStr')) {
        cache.deleteCache('servediscountStr')
        cache.deleteCache('currentPayEmpid')
        cache.deleteCache('servediscountprice')
      }
      if (cache.getCache('currentXianjinArr')) {
        cache.deleteCache('currentXianjinArr')
        cache.deleteCache('curentXianjinPriceArr')
        cache.deleteCache('curentOnlinePrice')
      }
    })


    const codeNum = ref('')

    onUnmounted(() => {
      initPay()
      emitter.off('changeOrderPayDialogClose')
      emitter.off('changeOrderPayDialogSearchPayState')
      emitter.off('changeOrderPayTypePageSetQrcode')
      emitter.off('changeOrderBillingCashPaySuccess')
    })


    const codeNumRef = ref()
    const iscodeNumFocus = ref(false)
    const codeNumfocus = () => {
      iscodeNumFocus.value = true
    }

    const codeNumblur = () => {
      iscodeNumFocus.value = false
    }


    return {
      codeNumfocus,
      codeNumblur,
      iscodeNumFocus,
      codeNumRef,
      codeNum,
      handleSetCodeClick,
      handleCashPayClick,
      currentPayType,
      handleOptPayClick,
      qrCodeSrc,
      isFirstClick,
      payType,
      COMPANY_ID,
      isPaySuccess,
      newOid,
      retryNum,
      testInput,
      xianjinStr,
      zhifu,
      handleTestInputChange,
      payWayId
    }

  }
})
</script>

<style scoped lang="less">
.pay-code {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  align-items: center;

  .codeNum {
    opacity: 0.1;
    margin-top: 100px;

    &:deep(.el-input) {
      opacity: 0.1;
    }
  }

  &>div {
    width: 100%;
  }

  .code {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 210px;
      height: 210px;
    }

    .opt-pay {
      text-align: center;
      margin-top: 30px;
      cursor: pointer;
    }
  }

  .title {
    margin-bottom: 20px;
    text-align: center;
  }
  .pay-price {
    text-align: center;
    font-size: 32px;
    color: #ff6600;
    font-weight: 650;
    margin-bottom: 30px;
  }
}
</style>
