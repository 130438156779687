<template>
  <div class="pay-type">
    <div class="title">
      <div>选择收款方式</div>
      <!-- <h1>{{ ruleForm.payType }}</h1> -->
      <div>组合收款
        <el-switch @change="handleSwichChange" :disabled="currentIndex == 2" v-model="ruleForm.payType" />
      </div>
    </div>

    <div class="pay-list">
      <div class="title">
        推荐收款
      </div>
      <div class="pay-item">
        <template :key="item" v-for="(item, index) in payCategory">
          <!-- 现金+线上组合支付时 不显示线及支付 -->
          <div v-if="!(ruleForm.payType == true && index == 2)" @click="handlePayCategoryClick(item, index)"
            :class="{ actives: currentIndex == index }" class="item">
            {{ item.name }}
          </div>
        </template>
      </div>

      <div class="title">
        记账收款
      </div>
      <div class="pay-item">
        <template :key="item" v-for="(item, index) in payBookkeepingCategory">
          <div @click="handlePayBookkeepingCategoryClick(item, index)" :class="{ actives: currentIndex2 == index }"
            class="item">
            {{ item.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, onUnmounted, ref, watch } from 'vue'
import { payCategory, payBookkeepingCategory } from '../../config'
import emitter from '@/utils/eventbus'
import { emit } from 'process'
import cache from '@/utils/cache'


export default defineComponent({
  props: {
    isKaika: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  emits: ['changePayType', 'changeOnlinePayType','changePayWay'],
  setup(props, { emit }) {

    const ruleForm = reactive({
      payType: false
    })

    watch(() => ruleForm.payType, (n) => {
      // console.log(n);
      // 发射 组合支付方式改变
      emit('changePayType', n)
    }, {
      deep: true,
    })

    const currentIndex = ref(0)
    const currentIndex2 = ref(null)

    // 支付方式改变
    const handlePayCategoryClick = (item, index) => {
      // console.log(item);
      // console.log(index);
      emit('changeOnlinePayType', index)
      emit('changePayWay', item.id)
      currentIndex.value = index
      currentIndex2.value = null
    }

    const handleZuhePayCategoryClick = (item, index) => {
      // console.log(item, index);
    }

    watch(currentIndex, (n) => {
      addQrcode()
    }, {
      deep: true
    })


    const addQrcode = () => {
      let payType = ''
      if (currentIndex.value == 0) {
        payType = 'wechat'
      } else if (currentIndex.value == 1) {
        payType = 'alipay'
      } else {
        payType = 'xianjin'
      }
      emitter.emit('changeOrderPayTypePageSetQrcode', payType)
    }

    emitter.on('changeQrcodePageOrderPayType', () => {
      addQrcode()
    })



    const handlePayBookkeepingCategoryClick = (item, index) => {
      currentIndex2.value = index
      currentIndex.value = null
      emit('changePayWay', item.id)
      
    }

    const handleSwichChange = flag => {
      if (flag == false) {
        cache.deleteCache('currentXianjinArr')
        cache.deleteCache('curentXianjinPriceArr')
        cache.deleteCache('curentOnlinePrice')
      }
    }

    onUnmounted(() => {
      emitter.off('changeQrcodePageOrderPayType')
    })

    return {
      handleSwichChange,
      handlePayCategoryClick,
      payBookkeepingCategory,
      ruleForm,
      payCategory,
      currentIndex,
      currentIndex2,
      handlePayBookkeepingCategoryClick,
      handleZuhePayCategoryClick
    }

  }
})
</script>

<style scoped lang="less">
.pay-type {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;
  }

  .pay-list {
    .title {
      margin-bottom: 20px;
    }

    .pay-item {
      display: flex;
      flex-wrap: wrap;

      .actives {
        background-color: rgb(255, 146, 36);
        color: white;
      }

      .item {
        width: 46%;
        line-height: 60px;
        margin: 0 1%;
        border: 1px solid rgb(221, 221, 221);
        border-radius: 6px;
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }

}
</style>




