<template>
  <div class="zuhe">
    <div class="sum">
      <div class="title">总计</div>
      <div class="value">¥{{ (order.unpaidPrice).toFixed(2) ?? "0.00" }}</div>
    </div>
    <div class="form">
      <el-form
        label-position="top"
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item :label="payWayName" prop="wx_al">
          <el-input
            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
            placeholder="0.00"
            v-model="ruleForm.wx_al"
            @change="changeInput"
          >
            <template #suffix>
              <el-button @click="handleSetQRcode">付款</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="现金" prop="xianjin">
          <el-input placeholder="0.00" readonly v-model="ruleForm.xianjin" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed,watch } from 'vue'
import { ElMessage } from 'element-plus'
import qrCode from '../qr-code/qr-code.vue'
import { backgroundPay } from '@/service/main/order'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'

export default defineComponent({
  props: {
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    // 当前支付方式 0 微信 1支付宝 2现金
    currentOnlinePayType: {
      type: Number,
      default: 0
    },
  },
  components: {
    qrCode
  },
  emits: ['paySuccess'],
  setup(props, { emit }) {
    const isClickQRcode = ref(false)
    const order = computed(() => props.curentOrderInfo)
    const currentOptPayType = computed(() => props.currentOnlinePayType)
    const ruleForm = reactive({
      wx_al: '',
      xianjin: ''
    })
    const payWayName = ref('微信')
    const getPayWay = () => {
        if (order.value.payWay=='miandan') {
          payWayName.value='免单'
        }else if(order.value.payWay=='tuangou'){
          payWayName.value='团购'
        }else if(order.value.payWay=='POS-machine'){
          payWayName.value='POS机'
        }else if(order.value.payWay=='B-endCooperation'){
          payWayName.value='B端合作收款'
        }else if(order.value.payWay=='duihuan'){
          payWayName.value='兑换券'
        }else if(order.value.payWay=='douyin'){
          payWayName.value='抖音'
        }else if(order.value.payWay=='NING_BO_BANK'){
          payWayName.value='宁波银行'
        }else if(order.value.payWay=='wx'){
          payWayName.value='微信'
        }else if(order.value.payWay=='zfb'){
          payWayName.value='支付宝'
        }else{
          payWayName.value='微信'
          order.value.payWay='wx'
        }
    }
    getPayWay()
    watch(order, (n) => {
      if (n.payWay=='miandan') {
        payWayName.value='免单'
      }else if(n.payWay=='tuangou'){
        payWayName.value='团购'
      }else if(n.payWay=='POS-machine'){
        payWayName.value='POS机'
      }else if(n.payWay=='B-endCooperation'){
        payWayName.value='B端合作收款'
      }else if(n.payWay=='duihuan'){
        payWayName.value='兑换券'
      }else if(n.payWay=='douyin'){
        payWayName.value='抖音'
      }else if(n.payWay=='NING_BO_BANK'){
        payWayName.value='宁波银行'
      }else if(n.payWay=='wx'){
        payWayName.value='微信'
      }else if(n.payWay=='zfb'){
        payWayName.value='支付宝'
      }
    }, {
      deep: true
    })
    const changeInput = () => {
      if (String(ruleForm.wx_al).length == 0) {
        ElMessage({
          message: '请正确填写数据!',
          type: 'warning',
        })
        ruleForm.xianjin=0
        return
      }


      if (Number(ruleForm.wx_al) <= 0) {
        ElMessage({
          message: '线上支付金必须大于0',
          type: 'warning',
        })
        ruleForm.xianjin=0
        return
      }

      if (ruleForm.wx_al > (order.value.unpaidPrice)) {
        ElMessage({
          message: '不得超过支付金额',
          type: 'warning',
        })
        ruleForm.xianjin=0
        return
      }
      ruleForm.xianjin = ((order.value.unpaidPrice)) - ruleForm.wx_al
    }
    const handleSetQRcode = async () => {
      if (String(ruleForm.wx_al).length == 0) {
        ElMessage({
          message: '请正确填写数据!',
          type: 'warning',
        })
        return
      }


      if (Number(ruleForm.wx_al) <= 0) {
        ElMessage({
          message: '线上支付金必须大于0',
          type: 'warning',
        })
        return
      }

      if (ruleForm.wx_al > (order.value.unpaidPrice)) {
        ElMessage({
          message: '不得超过支付金额',
          type: 'warning',
        })
        return
      }

      ruleForm.xianjin = ((order.value.unpaidPrice)) - ruleForm.wx_al
      if (order.value.payWay=='wx'||order.value.payWay=='zfb') {
          // 记录支付方式
          cache.setCache('currentXianjinArr', 'Cash')
          cache.setCache('curentXianjinPriceArr', ruleForm.xianjin)
          cache.setCache('curentOnlinePrice', ruleForm.wx_al)

          // 发射 组合支付事件
          emit('zuhePay')
          // 生成支付二维码
          setTimeout(() => {
            emitter.emit('changeOrderPayDialogSearchPayState')
          }, 2000);
      }else{
        const res = await backgroundPay({
          id: order.value.id,
          itemList:[{
            pawWay:order.value.payWay,
            price:ruleForm.wx_al
          },{
            pawWay:'Cash',
            price:ruleForm.xianjin
          }]
        })
        if (res.code == 0) {
          emit('paySuccess')
          ElMessage({
            message: '支付成功！',
            type: 'success',
          })
        } else {
          ElMessage.error('支付失败!')
        }
      }
    }


    return {
      handleSetQRcode,
      changeInput,
      ruleForm,
      order,
      isClickQRcode,
      payWayName,
      currentOptPayType,
      getPayWay
    }

  }
})
</script>

<style scoped lang="less">
.zuhe {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sum {
    text-align: center;

    .title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .value {
      color: rgb(255, 146, 36);
      font-size: 36px;
    }
  }

  .form {
    max-width: 400px;
    margin-top: 50px;

    &:deep(.el-input) {
      min-width: 260px;

      .el-input__suffix {
        right: 0 !important;
      }
    }
  }
}
</style>
