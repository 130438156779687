<template>
  <div class="pay">
    <div class="main-content">
      <div class="pay-type">
        <payType @changeOnlinePayType="changeOnlinePayType" :isKaika="isKaika" @changePayType="changePayType"  @changePayWay="changePayWay"></payType>
      </div>
      <div class="QR-code">
        <template v-if="isZuheFlag">
          <zuhe @zuhePay="zuhePay" v-if="!isClickZuhePay" :curentOrderInfo="curentOrderInfo" @paySuccess="paySuccess" :currentOnlinePayType="currentOnlinePayType"></zuhe>
          <payCode :isZuheFlag="isZuheFlag" :curentOrderInfo="curentOrderInfo" v-else
            :currentOnlinePayType="currentOnlinePayType" :isSetZuheXianjinPayCode="isSetZuhePayCode"
            @setPayCodeClick="setPayCodeClick" :reserveNo="reserveNo" :isKaika='isKaika' :isBack="isBack"
            :payPrice="payPrice" @paySuccess="paySuccess" :orderKind="orderKind" :oid="oid">
          </payCode>
        </template>
        <template v-else>
          <payCode :isZuheFlag="isZuheFlag" :curentOrderInfo="curentOrderInfo"
            :currentOnlinePayType="currentOnlinePayType" :isSetZuheXianjinPayCode="isSetZuhePayCode"
            @setPayCodeClick="setPayCodeClick" :reserveNo="reserveNo" :isKaika='isKaika' :isBack="isBack"
            :payPrice="payPrice" @paySuccess="paySuccess" :orderKind="orderKind" :oid="oid">
          </payCode>
        </template>
      </div>
      <div class="pay-receipt">
        <payReceipt :isKaika="isKaika" :curentOrderInfo="curentOrderInfo"></payReceipt>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import payType from './cpns/pay-type/pay-type.vue'
import payReceipt from './cpns/pay-receipt/pay-receipt.vue'
import payCode from './cpns/qr-code/qr-code'
import { emit } from 'process'
import zuhe from './cpns/zuhe/zuhe.vue'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import { updateZuheInfo } from '@/service/main/order'

export default defineComponent({
  props: {
    oid: {
      type: Number,
      default: -1
    },
    orderKind: {
      type: Number,
      default: 0
    },
    payPrice: {
      type: Number,
      default: 0
    },
    isBack: {
      type: Boolean,
      default: false
    },
    reserveNo: {
      type: Number,
      default: -1
    },
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    isKaika: {
      type: Boolean,
      default: false
    }
  },
  components: {
    payType,
    payReceipt,
    payCode,
    zuhe
  },
  emits: ['paySuccess', 'changePayType'],
  setup(props, { emit }) {

    const paySuccess = () => {
      isPayS.value = true
      emit('paySuccess')
    }

    const changePayType = async flag => {
      // console.log(flag);
      // 发射 生成支付二维码
      setTimeout(() => {
        emitter.emit('changeOrderPayDialogSearchPayState')
      }, 2000);

      // 改变组合支付flag值
      isZuheFlag.value = flag
      // 改变组合支付点击二维码flag 值
      isClickZuhePay.value = !flag
      emit('changePayType', flag)

      // 如果不是组合支付 当现在已经生成二维码时
      if (flag == false && isSetZuhePayCode.value == true) {
        // 设置组合支付点击二维码flag 值为false
        isSetZuhePayCode.value = false
        // 现金组合支付更新订单信息
        await updateZuheInfo({
          subOrderNo: props.curentOrderInfo.oid,
          payPrice: props.curentOrderInfo.waitPayPrice,
          cashPrice: 0
        })
      }
    }

    // 组合支付时是否点击了生成二维码
    const isClickZuhePay = ref(false)

    // 组合支付 点击完付款后的处理函数
    const zuhePay = () => {
      // 改变组合支付 扫码界面
      isClickZuhePay.value = true
    }


    const isPayS = ref(false)
    // 组合支付和点击生成二维码 flag
    const isSetZuhePayCode = ref(false)
    // 如果点击了生成二维码
    const setPayCodeClick = () => {
      // 如果点击过组合支付
      if (isClickZuhePay.value == true) {
        // 既点击过组合支付 又点击生成二维码
        isSetZuhePayCode.value = true
      }
    }

    // 是否是组合支付
    const isZuheFlag = ref(false)
    const currentOnlinePayType = ref(0)
    const changeOnlinePayType = index => {
      currentOnlinePayType.value = index
    }
    const changePayWay = payWay => {
		// cache.setCache('lastPayType', payWay)
      props.curentOrderInfo.payWay=payWay
    }
    


    return {
      currentOnlinePayType,
      changeOnlinePayType,
      changePayWay,
      changePayType,
      isSetZuhePayCode,
      paySuccess,
      isZuheFlag,
      isClickZuhePay,
      zuhePay,
      setPayCodeClick,
      isPayS
    }

  }
})
</script>

<style scoped lang="less">
&:deep(.el-dialog__body) {
  margin-top: 10px;
}

.pay {
  background-color: white;
  min-height: 600px;
  border-top: 1px solid #ccc;

  .top-form {
    display: flex;
    justify-content: space-between;
  }

  .main-content {
    display: flex;

    .pay-type {
      margin-right: 20px;
    }

    .pay-type,
    .pay-receipt {
      width: 300px;
      padding: 20px;
    }

    .QR-code {
      margin-top: 20px;
      padding: 20px;
      flex: 1;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
  }
}
</style>




