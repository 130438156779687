<template>
  <div class="pay-receipt">
    <div class="title-name">一指阁</div>
    <div class="shop-name">({{ ruleForm.shopName }})</div>
    <div class="title">
      <div class="line"></div>
      <div class="value">订单信息</div>
      <div class="line"></div>
    </div>
    <div class="item">
      <div class="key">订单号</div>
      <div class="value">{{ ruleForm.orderNo }}</div>
    </div>
    <div class="item">
      <div class="key">下单时间</div>
      <div class="value">{{ ruleForm.orderDate }}</div>
    </div>
    <div class="item">
      <div class="key">会员号</div>
      <div class="value">{{ ruleForm.userId }}</div>
    </div>
    <div class="item">
      <div class="key">会员姓名</div>
      <div class="value">{{ ruleForm.userName }}</div>
    </div>
    <div class="item">
      <div class="key">会员手机号</div>
      <div class="value">{{ ruleForm.userPhone }}</div>
    </div>

    <div class="title">
      <div class="line"></div>
      <div class="value">消费明细</div>
      <div class="line"></div>
    </div>
    <div class="goods-list">
      <template :key="item" v-for="item in ruleForm.goodsList">
        <div v-if="!isKaika" class="goods-item">
          <template v-if="item.buyNum">
            <div class="goods-name">
              {{ item.cardName }}
            </div>
            <div class="buy-num">
              <div class="left">数量/原价</div>
              <div class="num">x{{ item.buyNum }}</div>
              <div class="price">{{ (item.buyNum * item.salePrice).toFixed(2) }}</div>
            </div>
            <div class="min-num">
              <div class="left">小计</div>
              <div class="price">{{ (item.buyNum * item.salePrice).toFixed(2) }}</div>
            </div>
          </template>

        </div>

        <div v-else class="goods-item">
          <template v-if="item.qty">
            <div class="goods-name">
              {{ item.itemName }}
            </div>
            <div class="buy-num">
              <div class="left">数量/原价</div>
              <div class="num">x{{ item.qty }}</div>
              <div class="price">{{ (item.qty * item.salePrice).toFixed(2) }}</div>
            </div>
            <div class="min-num">
              <div class="left">小计</div>
              <div class="price">{{ (item.qty * item.salePrice).toFixed(2) }}</div>
            </div>
          </template>

        </div>
      </template>
    </div>
    <div class="wait-pay">
      <div class="key">
        待付款
      </div>
      <div class="value">
        ¥{{ (ruleForm.totalPrice).toFixed(2) }}
      </div>
    </div>
    <div class="footer">
      <div>扫码收藏店铺,随时可预约</div>
      <div>谢谢光临 欢迎再来</div>
      <div class="qr-code"></div>
      <div class="shop-arrress">{{ ruleForm.shopAddress }}</div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import cache from '@/utils/cache'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
export default defineComponent({
  props: {
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    isKaika: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    // const userPhone = computed(() => route.query.phone)
    const ruleForm = reactive({
      shopName: "淮海路店",
      orderDate: '',
      userName: '',
      userPhone: '',
      waitPayPrice: 0,
      goodsList: [],
      orderNo: '',
      userId: '',
      shopAddress: '',
      totalPrice: ''

    })
    const initPage = () => {
      // store.dispatch('order/getUserInfoAction', userPhone.value)
    }


    // const userDetail = computed(() => store.state.order.userDetail)

    // watch(userDetail, (n) => {
    //   if (n != null) {
    //     ruleForm.userName = n.userRealname
    //     ruleForm.userPhone = n.userMobile
    //     ruleForm.userId = n.userId
    //   }
    // }, {
    //   deep: true,
    //   immediate: true
    // })

    watch(() => props.curentOrderInfo, (n) => {
      if (n != null) {
        ruleForm.userName = n.userName
        ruleForm.userPhone = n.phone
        ruleForm.userId = n.userId
        ruleForm.totalPrice = n.unpaidPrice
        ruleForm.orderNo=n.code
        ruleForm.orderDate=n.createTime
        ruleForm.shopName=n.shopName
        // initPage()
      }
    }, {
      deep: true,
      immediate: true
    })


    return {
      ruleForm,
      dayjs,
      // userPhone,
      // userDetail
    }

  }
})
</script>

<style scoped lang="less">
.pay-receipt {
  .shop-name {
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
  }

  .footer {
    text-align: center;
    font-size: 12px;

    &>div {
      margin: 10px 0;
    }
  }

  .wait-pay {
    display: flex;
    font-weight: 550;
    font-size: 24px;
    justify-content: space-between;
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px dotted rgb(89, 89, 89);
  }

  .goods-list {

    .goods-item {
      margin: 8px 0;
      font-size: 12px;

      .goods-name {

        color: rgb(166, 166, 166);
      }

      .buy-num {
        display: flex;
        margin: 8px 0;
        font-size: 12px;

        &>div {
          flex: 1;
          color: rgb(166, 166, 166);
        }

        .left {
          text-align: left;
        }

        .num {
          text-align: center;
        }

        .price {
          text-align: right;
        }
      }

      .min-num {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px dotted rgb(89, 89, 89);

        .left {}

        .price {}
      }

    }
  }

  .title {
    text-align: center;
    margin: 24px 0 10px 0;
    position: relative;
    display: flex;

    .line {
      position: relative;
      flex: 1;
      border-bottom: 1px dotted rgb(89, 89, 89);
      top: -6px;
    }

    .value {
      width: 90px;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    font-size: 12px;

    .key {
      color: rgb(166, 166, 166);
    }
  }

  .title-name {
    text-align: center;
    font-size: 24px;
    font-weight: 550;

  }
}
</style>




