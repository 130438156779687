export const payCategory=[
  {
    name:"微信",
    id:'wx'
  },
  {
    name:"支付宝",
    id:'zfb'
  },
  {
    name:"现金",
    id:'Cash'
  },
  {
    name:"免单",
    id:'miandan'
  }
]

export const payBookkeepingCategory=[
  // {
  //   name:"团购(新美大)",
  //   id:"tuangou",
  // },
  {
    name:"POS机(记账收款)",
    id:"POS-machine",
  },
  {
    name:"B端合作收款",
    id:"B-endCooperation",
  },
  {
    name:"兑换券",
    id:"duihuan",
  },
  {
    name:"抖音",
    id:"douyin",
  },
  {
    name:"宁波银行",
    id:"NING_BO_BANK",
  }
]

