<template>
  <div class="cash">
    <div class="pay-price">¥{{ (payInfo.unpaidPrice).toFixed(2) }}</div>
    <div class="top-form" v-if="curentOrderInfo.payWay!='miandan'">
      <el-input clearable @change="handleInputChange"
        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
        v-model="ruleForm.cash" placeholder="0.00" />
      <div @click="handleSmallCahngeClikc" class="small-change">不用找零</div>
    </div>
    <div v-show="ruleForm.isBack&&curentOrderInfo.payWay!='miandan'" class="tips">
      <div>找零:</div>
      <div class="back">¥{{ ruleForm.cashChange.toFixed(2) }}</div>
    </div>
    <div class="bottom-btn">
      <el-button @click="handlePayClick" size="large" type="primary">{{curentOrderInfo.payWay=="miandan"?"确认免单":"确认收款"}}</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { backgroundPay } from '@/service/main/order'
import { useRoute } from 'vue-router'
import cache from '@/utils/cache'
import { popup } from '@/utils/pop-up'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import { messageNotify1 } from '@/service/main/base'
import { getOrderDetail } from '@/service/main/order'
import { COMPANY_ID } from '@/common/constant'

export default defineComponent({
  props: {
    payInfo: {
      type: Object,
      required: true
    },
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {

  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const orderInfo = computed(() => props.payInfo)
    const ruleForm = reactive({
      cash: '',
      cashChange: 0,
      isBack: false
    })

    const handleInputChange = (value) => {
      count()
    }

    const count = () => {
      let payPrice = (orderInfo.value.unpaidPrice)
      if (ruleForm.cash > payPrice) {
        ruleForm.cashChange = ruleForm.cash - payPrice
        ruleForm.isBack = true
      }

      if (ruleForm.cash == '') {
        ruleForm.isBack = false
        ruleForm.cashChange = 0
      }

    }

    const handleSmallCahngeClikc = () => {
      ruleForm.cash = ((orderInfo.value.unpaidPrice)).toFixed(2)
      ruleForm.isBack = false
      ruleForm.cashChange = 0
    }

    const handlePayClick = async () => {
      let payPrice = (orderInfo.value.unpaidPrice)


      if (ruleForm.cash < payPrice) {
        ElMessage({
          message: '输入的金额不得小于订单总金额!',
          type: 'warning',
        })
        return
      }
      count()
      // popup('确认现金收款吗?', async () => {


        // const adminId = cache.getCache('adminInfo')
        // let currentPayEmpid = cache.getCache('currentPayEmpid')
        // currentPayEmpid = currentPayEmpid == '' ? 0 : currentPayEmpid
        // // 判断是否是买卡或余额充值
        // let isOpenKaika = props.curentOrderInfo.isOpenKaika
        // let ordeKind = 0

        // if (isOpenKaika) {
        //   ordeKind = props.curentOrderInfo.consumeList[0].kindId
        // }
        const res = await backgroundPay({
          id: orderInfo.value.id,
          itemList:[{
            pawWay:props.curentOrderInfo.payWay,
            price:props.curentOrderInfo.unpaidPrice
          }]
        })

        // 获取订单详情
        // const detailRes = await getOrderDetail(COMPANY_ID, props.curentOrderInfo.oid)
        // let { orderDetail, empinfo, payinfo, serveItems } = detailRes.data
        // let { subOrderNo, shopName, userId } = orderDetail
        // let appTime = serveItems[0]['arriveDate'] + ' ' + serveItems[0]['startTime']
        // let messageObj = {
        //   orderNo: subOrderNo,
        //   Price: payinfo.payPrice,
        //   shopName,
        //   appTime,
        //   userId,
        //   empName: empinfo[0]['userRealname']
        // }

        // 后台开单使用现金/在线支付通知
        // await messageNotify1(messageObj)

        if (res.code == 0) {
          emitter.emit('changeOrderBillingCashPaySuccess')
        } else {
          ElMessage.error('支付失败!')
        }
      // })

    }

    const initPage = () => {
      let info = orderInfo.value
      ruleForm.cash = (info.unpaidPrice).toFixed(2)
    }
    initPage()

    // emitter.on('changeOrderPayTypePageSetQrcode', async _payType => {
    //   payType.value = _payType
    //   if (_payType=="xianjin") {
    //     initPay()
    //   }else{
    //     flag = true
    //     getPayState(props.curentOrderInfo.id)
    //     setQrCode()
    //   }
    // })

    // onUnmounted(() => {
    //   emitter.off('changeOrderPayTypePageSetQrcode')
    // })

    return {
      handlePayClick,
      orderInfo,
      handleSmallCahngeClikc,
      handleInputChange,
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.cash {
  width: 100%;

  .pay-price {
    text-align: center;
    font-size: 32px;
    color: #ff6600;
    font-weight: 650;
    margin-bottom: 30px;
  }

  .tips {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .back {
      margin-left: 10px;
      color: #ff6600;
    }
  }

  .bottom-btn {
    display: flex;
    justify-content: center;
    bottom: 0;
    position: relative;
    top: 300px;

    &:deep(.el-button) {
      width: 240px;
      height: 46px;
    }
  }

  .top-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .small-change {
      margin-left: 20px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }

    &:deep(.el-input) {
      max-width: 200px;
    }
  }
}
</style>
